/* Box Shadows */
.elevation-0 {
    box-shadow: none !important;
}

.elevation-1 {
    box-shadow: 0px 0px 2px 0px rgba(141, 141, 148, 0.16), 0px 2px 6px -4px rgba(141, 141, 148, 0.48);
}

.elevation-2 {
    box-shadow: 0px 0px 2px 0px rgba(141, 141, 148, 0.14), 0px 4px 8px -4px rgba(141, 141, 148, 0.36);
}

.elevation-3 {
    box-shadow: 0px 0px 2px 0px rgba(141, 141, 148, 0.12), 0px 0px 4px 0px rgba(141, 141, 148, 0.16), 0px 8px 12px -4px rgba(130, 136, 148, 0.24);
}
