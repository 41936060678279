.grid {
  &.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .col-sm {
    flex: 1;
    padding-right: 15px;
    padding-left: 15px;
    box-sizing: border-box;
  }

  /* Ensuring columns take equal space */
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
  }

  @media (min-width: 576px) {
    .col-sm {
      max-width: none;
    }
  }
}
