.ng-dropdown-panel .ng-option.ng-option-marked {
  background-color: rgba(0, 0, 0, 0.04) !important;
  color: #000 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: #333;
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.ng-dropdown-panel {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  border-color: #ccc;
  box-shadow: none;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: pre-wrap;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  display: none !important;
}

.ng-select .ng-arrow-wrapper::after {
  font-family: 'Material Icons';
  content: 'keyboard_arrow_down';
  font-size: 24px;
  color: var(--color-gray-1000);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.ng-select.ng-select-opened .ng-arrow-wrapper::after {
  content: 'keyboard_arrow_up';
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: var(--color-gray-F0F0F5);
}

.ng-select .ng-select-container .ng-value-container {
  margin-right: 10px;
}

.ng-select.ng-select-disabled>.ng-select-container {
  background-color: var(--color-gray-F0F0F5);
}

.ng-select.custom-select-input-error{
  .ng-select-container {
    border: 1px solid var(--warning-color-dark) !important;
  }
}

.ng-select.default-mat-search {
  .ng-arrow-wrapper .ng-arrow {
    display: inline-block !important;
  }
  .ng-arrow-wrapper::after {
    display: none;
  }
  .ng-select-container, .ng-select-container .ng-value-container .ng-input>input  {
    height: 48px
  }
  .ng-select-container, .ng-select-container .ng-value-container .ng-input {
    top: 0;
  }
  .ng-value-Container {
    align-items: center;
  }
  .ng-select-container {
    border: 1px solid #b0b0b0;
    &:hover {
      border: 1px solid #000;
    }
  }
}
.ng-select.default-mat-search.ng-select-focused .ng-select-container {
  border: 1px solid var(--primary-color-light);
}
.ng-select.default-mat-search.ng-invalid .ng-select-container {
  border: 1px solid var(--warning-color);
}
  