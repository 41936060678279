/* You can add global styles to this file, and also import other style files */
@use "styles/themes.scss";
@use "styles/variables.scss";
@use "styles/custom-material.scss";
@use "styles/text_styles.scss";
@use "styles/buttons.scss";
@use "styles/admin.scss";
@use "styles/utilities.scss";
@use "styles/checkbox.scss";
@use "styles/custom-ng-select.scss";
@use "styles/custom-bootstrap.scss";
@use "styles/form-control.scss";

:root {
  --mdc-plain-tooltip-supporting-text-tracking: 0px;
  --mdc-outlined-button-label-text-tracking: 0px;
  --mat-table-row-item-label-text-tracking: 0px;
  --mat-form-field-subscript-text-tracking: 0px;
  --mdc-typography-button-letter-spacing: 0px;
  --mdc-checkbox-state-layer-size: 18px;
  --mdc-plain-tooltip-supporting-text-size: 10px;
  --mdc-plain-tooltip-supporting-text-line-height: 20px;
  --mdc-plain-tooltip-container-color: rgba(97, 97, 97, 0.85);
  --mat-icon-button-focus-state-layer-opacity: 0;
  --mdc-radio-state-layer-size: 20px;
  --mdc-filled-button-label-text-tracking: 0px;
  --mat-option-label-text-size: 12px;
  --mdc-filled-button-label-text-size: 12px;
  --mat-table-row-item-container-height: 48px;
  --mat-table-footer-container-height: 48px;

  .mat-mdc-standard-chip {
    --mdc-chip-label-text-weight: 500;

    &.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__action--primary {
      padding-right: 8px;
    }

    &.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__graphic {
      padding-left: 4px;
      padding-right: 4px;
    }
  }

  .mat-mdc-icon-button:hover > .mat-mdc-button-persistent-ripple::before {
    --mat-icon-button-focus-state-layer-opacity: 0.12;
  }

  .mat-mdc-slide-toggle {
    --mdc-switch-track-height: 24px;
    --mat-switch-selected-with-icon-handle-horizontal-margin: 0 8px;
    --mat-switch-unselected-with-icon-handle-horizontal-margin: 0 2px;
    --mdc-switch-track-shape: 999px;
    --mdc-switch-track-width: 45px;
    --mdc-switch-handle-shape: 999px;
    --mdc-switch-unselected-handle-color: var(--color-white);
    --mdc-switch-unselected-focus-handle-color: var(--color-white);
    --mdc-switch-unselected-hover-handle-color: var(--color-white);
    --mdc-switch-unselected-pressed-handle-color: var(--color-white);
    --mdc-switch-unselected-focus-track-color: var(--color-gray-12);
    --mdc-switch-unselected-hover-track-color: var(--color-gray-12);
    --mdc-switch-unselected-pressed-track-color: var(--color-gray-12);
    --mdc-switch-unselected-track-color: var(--color-gray-12);
    --mdc-switch-selected-handle-color: var(--color-white);
    --mdc-switch-selected-focus-handle-color: var(--color-white);
    --mdc-switch-selected-hover-handle-color: var(--color-white);
    --mdc-switch-selected-pressed-handle-color: var(--color-white);
    --mdc-switch-selected-focus-track-color: var(--success-color);
    --mdc-switch-selected-hover-track-color: var(--success-color);
    --mdc-switch-selected-pressed-track-color: var(--success-color);
    --mdc-switch-selected-track-color: var(--success-color);

    .mdc-switch__ripple {
      display: none;
    }

    &.mat-accent {
      @extend .mat-mdc-slide-toggle
    }
  }

  .mat-mdc-tab-header {
    --mat-tab-header-label-text-tracking: 0;

    .mdc-tab--active .mdc-tab__text-label {
      font-weight: 900;
    }
  }
}

* {
  font-family: 'Inter', sans-serif
}

html, body, .container {
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  font-size: 14px;
  color: var(--color-black-text) !important;
}

textarea:focus,
input:focus {
  outline: none;
}

// Common styles for entire application
.dialog-button {
  min-width: 120px !important;
}

// Material theme customisation
.mat-mdc-form-field {
  padding-bottom: 1.4em;
  position: relative;

  &:has(.mat-mdc-form-field-textarea-control) .mat-mdc-form-field-icon-prefix > .mat-icon {
    transform: translateY(-7px);
  }
}

.mat-mdc-text-field-wrapper {
  flex: none !important;
}

.mat-mdc-select-arrow-wrapper {
  height: 16px !important;
}

.mat-form-field-appearance-outline {
  .mat-mdc-form-field-flex {
    padding: 0 .75em;
  }

  &:not(:has(.mat-mdc-form-field-icon-prefix)):has(.mdc-floating-label) .mat-mdc-form-field-flex {
    padding: 0 0 0 1.25em;
  }

  .mdc-text-field--outlined {
    padding: 0;
  }
}

.mdc-text-field--invalid {
  .mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix {
    > .mat-icon {
      color: var(--mdc-outlined-text-field-error-caret-color);
    }
  }
}

.mat-form-field-disabled .mat-mdc-form-field-infix {
  z-index: 1;
}

mat-form-field .mat-mdc-form-field-subscript-wrapper {
  position: absolute;
  bottom: -2px;

  &:not(:has(mat-hint):has(mat-error)) {
    display: none;
  }

  &:has(mat-hint), &:has(mat-error) {
    display: block !important;
  }

  mat-hint {
    opacity: 0.7;
    font-size: 11px;
  }
}

.mat-mdc-tooltip-surface {
  padding: 4px 8px !important;
  min-width: 100px !important;
  max-width: 300px !important;
  padding: 1px 5px !important;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mdc-switch__icons {
  display: none;
}

.mat-mdc-tab {
  flex-grow: 1;
  text-align: left;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  mat-icon {
    margin-right: 8px;
  }
}

.mdc-tab__text-label mat-icon {
  margin-right: 10px;
  font-size: 18px;
  height: 18px;
  width: 18px;
}

.mat-mdc-table {
  .mdc-data-table__row.expanded-row {
    background: initial !important;
  }

  .mdc-data-table__row.detail-row {
    height: 0 !important;
    background: initial !important;
  }

  .expanded-row + .mdc-data-table__row.detail-row {
    height: 52px !important;
  }

  td.mat-mdc-cell:last-child:not(.mat-column-expandedDetail):has(mat-icon:only-child) {
    padding: 0 !important;
  }
}

.mat-mdc-radio-button {
  .mdc-radio, .mdc-radio__background {
    width: var(--mdc-radio-state-layer-size, 40px) !important;
    height: var(--mdc-radio-state-layer-size, 40px) !important;
  }

  .mdc-radio .mdc-radio__inner-circle {
    border-width: calc(var(--mdc-radio-state-layer-size, 40px) / 2) !important;
  }
}

mat-slider {
  flex-grow: 1;
  margin: 0 10px;
}

.mdc-switch--selected .mdc-switch__handle-track {
  transform: translateX(calc(100% - 10px)) !important;
}

.mat-mdc-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px 8px 0 0;
  padding: 0 24px;
}

.hide-tab-header .mat-mdc-tab-header {
  display: none;
}

.mat-mdc-tab-disabled {
  cursor: not-allowed !important;
}

.mdc-icon-button {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

:not(app-searchable-multiselect) {
  .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
    top: 22px !important;
    margin-left: 23px;
  }

  .mat-mdc-form-field-flex:not(:has(.mat-mdc-form-field-icon-prefix)) .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
    margin-left: 0;
  }
}

.mat-mdc-form-field-type-mat-date-range-input .mat-mdc-input-element {
  font-size: 12px !important;
}

.mat-mdc-form-field-focus-overlay {
  display: none !important;
}

.mat-form-field-appearance-outline .mat-mdc-form-field-icon-prefix {
  margin: 0 3px 0 8px;
}

.mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix {
  line-height: normal !important;
}

.mat-mdc-form-field-icon-prefix > .mat-icon {
  padding: 5px 0 7.5px !important;
  font-size: 16px;
  margin-top: 4px;
  height: 16px;
  width: 16px;
}

.mat-mdc-form-field-icon-suffix:has(.mat-datepicker-toggle) .mat-mdc-icon-button.mat-mdc-button-base {
  transform: translateX(-7px);
}

.mat-mdc-form-field-flex .mat-icon {
  color: var(--color-gray-12);
}

.mat-mdc-form-field-infix {
  min-height: 0 !important;
}

.mat-form-field-appearance-outline .mdc-notched-outline--upgraded {
  .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
    border-width: 1px !important;
  }
}

.mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-floating-label {
  transition: transform 400ms ease, color 400ms ease, width 400ms ease !important;
}

.mat-mdc-autocomplete-panel {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2) !important;
  padding: 0 !important;
}

.mat-mdc-option {
  border-bottom: 1px solid #ddd;
}

.mat-mdc-select-disabled .mat-mdc-select-value {
  color: #333 !important;
}

.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: #e6e6e6 !important;
}

.chip-row {
  top: 5px !important;
}

.mat-mdc-chip-remove .mat-icon {
  margin-top: 0 !important;
}

.mat-mdc-select-arrow {
  margin-right: 1.25em !important;
}

.search {
  ::placeholder {
    color: var(--color-gray-12) !important;
  }

  .mat-mdc-input-element {
    font-size: 12px !important;
  }

  .mat-mdc-form-field-flex {
    align-items: center;
    padding: 0 !important;
    width: 320px;
  }

  .mat-mdc-form-field-infix {
    padding: 9.5px 2px !important;
    border: none;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) {
    .mat-mdc-notch-piece {
      border-color: var(--color-gray-ccc);
    }
  }

  &.mat-mdc-form-field {
    padding: 0;
    margin: 0;
  }

  .search-icon {
    width: 16px;
    height: 16px;
    color: #d8d8d8;
    font-size: 21px !important;
  }
}

.appearance-none {
  .mat-mdc-text-field-wrapper {
    background-color: transparent !important;
    padding: 0 !important;
  }

  .mdc-line-ripple {
    display: none !important;
  }

  .mat-mdc-form-field-infix {
    padding: 0 !important;
    min-height: auto !important;
  }
}

.search-container {
  height: 32px;
  width: 320px;
}

.search-result-con {
  padding: 6px 18px 5px 12px;
  cursor: pointer;
  border-bottom: 1px solid #e6e6eb;
}

.search-result-con:hover {
  background-color: rgb(240, 240, 245);
}

.main-search .search-button {
  min-width: 180px !important;
  width: unset !important;
}

*:has(+ .file-con) mat-form-field {
  padding-bottom: 0;
}

// Dialog overlay style
.shared-dialog {
  position: relative !important;
  width: 80%;
  max-width: 450px !important;
  min-height: 250px;
  background-color: var(--color-white);
  overflow-y: auto;
  border-radius: 15px;
}

.shared-dialog-red {
  position: relative !important;
  width: 80%;
  max-width: 450px !important;
  min-height: 250px;
  background-color: var(--color-white);
  overflow-y: auto;
  border-radius: 15px;

  .title-prefix {
    color: red !important;
  }
}

// Browse skills dialog
.browse-skills {
  max-width: 100% !important;
}

.mat-mdc-dialog-surface {
  padding: 24px;
}

.global-search, .transparent-bg, .browse-skills, .resume-builder, .notification-dialog, .dd-modal, .profile-modal, .skill-list-dialog, .skill-cluster-dialog {
  .mat-mdc-dialog-surface {
    padding: 0;
  }
}

.browse-skills, .oauth2-dialog, .faq-dialog, .skill-cluster-dialog, .skill-dialog, .notification-dialog, .template-dialog {
  background: var(--color-beige);

  .mat-mdc-dialog-surface {
    background: var(--color-beige);
  }
}

.mdc-button__label {
  white-space: nowrap;
}

.global-search {
  .mat-mdc-dialog-container {
    height: fit-content !important;
    border-radius: 8px !important;
  }
  &.cdk-overlay-pane {
    width: 50vw !important;
    max-width: 80vw !important;
    position: absolute !important;
    top: 200px !important;
  }
}

.global-search-max-height {
  max-height: calc(100vh - 350px) !important;
}

//Star rating menu
::ng-deep .star-menu .mat-mdc-menu-content {
  padding: 0 !important;
}

.star-menu {
  padding: 6px 20px;
  min-height: 45px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

//Slider rating menu
.proficiency-slider-menu{
  min-height: 45px !important;
  min-width: 366px !important;
  border-radius: 8px !important;
  box-shadow : 0px 0px 2px 0px rgba(141, 141, 148, 0.12), 0px 0px 4px 0px rgba(141, 141, 148, 0.16), 0px 8px 12px -4px rgba(130, 136, 148, 0.24) !important;

  .mat-mdc-menu-content{
    padding: 0 !important;
  }
}

// Common styles to all pages
.page-title {
  margin-bottom: 20px;
  font-weight: 600;
}

.page-con {
  margin-bottom: 82px;
  padding: 28px 28px 44px;
  border-radius: 8px;
  box-shadow: 0 4px 8px -2px rgba(141, 141, 148, 0.28), 0 0 2px 0 rgba(141, 141, 148, 0.16);
  background-color: var(--color-white);
}

.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 100px 0;
}

.no-data img {
  height: 96px;
  margin-bottom: 20px;
}

.transparent-bg {
  .mat-mdc-dialog-container .mat-mdc-dialog-surface {
    background: transparent !important;
    box-shadow: none !important;
  }
}

.checkbox {
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin: 0 8px 0 0;
  border-radius: 4px;
  border: solid 1px #e6e6eb;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  cursor: pointer;
}

.checkbox-selected {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.mdc-menu-surface.mat-mdc-select-panel {
  padding: 0 !important;
  overflow-x: hidden;

  &.myPanelClass {
    min-width: 125%;
  }

  mat-option span.mdc-list-item__primary-text {
    font-size: 12px;

    + mat-pseudo-checkbox {
      display: none;
    }
  }
}

.mdc-checkbox__background {
  border-radius: 4px !important;
}

.skill-item-con {
  display: flex;
  flex-wrap: wrap;
}

.skill-item {
  padding: 6px 10px;
  border-radius: 4px;
  background-color: #f0f0f5;
  border: 1px solid #f0f0f5;
  margin-bottom: 10px;
  margin-right: 12px;
  display: flex;
  align-items: center;
}

.skill-item mat-icon {
  font-size: 130%;
  margin-left: 4px;
  height: 14px;
  width: 14px;
  transform: translateY(-2px);
}

.skill-item:hover {
  border: 1px solid var(--primary-color);
}

.skill-tag {
  border-radius: 4px;
  padding: 4px 8px;
  background: #F0F0F5;
  text-align: center;
  display: inline-block;
}

.nowrap {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.viewall {
  padding: 10px 15px;
  cursor: pointer;
  font-size: 86%;
  position: sticky;
  bottom: 0;
  background-color: white;
  border-top: 1px solid #e6e6eb;
  z-index: 10;
}

.welcome-career-dialogue .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  background: rgba(255, 255, 255, 1);
  background: linear-gradient(rgb(175, 217, 255), rgb(255, 255, 255))
}

// Filters
.filter {
  margin-right: 20px;
  padding: 0 !important;
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.42);
  
  .mat-mdc-form-field-flex {
    padding-right: 0 !important;
    
    .mat-mdc-notch-piece {
      border-color: rgba(0, 0, 0, 0.12);
    }

    .mat-mdc-form-field-infix {
      padding: 8px 0 10px 0 !important;
      border: none !important;
      margin: 6px 0px 6px 0px;

      .mat-mdc-select {
        font-size: 12px !important;
        color: var(--color-gray) !important;
        line-height: 1.14em !important;
      }
    }

    .mat-mdc-form-field-icon-suffix {
      top: 0.15em !important;

      .mat-icon {
        color: #4d4d4d !important;
        font-size: 18px !important;
        height: 18px;
        width: 18px;
      }
    }
  }
}

.clearall {
  padding: 10px 15px;
  font-size: 80%;
  cursor: pointer;
}

.clearall:hover {
  opacity: 0.7;
}

.mobile-flex-line-break {
  display: none;
}

@media screen and (max-device-width: 900px) {
  body {
    font-size: 85% !important;
  }

  .fill-button,
  .empty-button,
  .empty-button-gray,
  .empty-button-secondary,
  .empty-button-warn {
    min-width: 80px !important;
  }

  th.mat-mdc-header-cell,
  td.mat-mdc-cell {
    font-size: 12px;
  }

  th.mat-mdc-header-cell:last-of-type,
  td.mat-mdc-cell:last-of-type,
  td.mat-mdc-footer-cell:last-of-type {
    padding-right: 12px !important;
  }

  th.mat-mdc-header-cell:first-of-type,
  td.mat-mdc-cell:first-of-type,
  td.mat-mdc-footer-cell:first-of-type {
    padding-left: 12px !important;
  }

  .page-con {
    padding: 12px;
  }

  .filter {
    width: 100%;
    margin-bottom: 10px;
  }

  .mobile-flex-line-break {
    display: block;
    width: 100%;
  }
}

mat-icon {
  font-family: 'Material Icons' !important;
}

.mt--2px {
  margin-top: -2px;
}

.h-24px {
  height: 24px !important;
}

.data-con {
  background: var(--color-white);
  box-shadow: 0px 0px 2px rgba(141, 141, 148, 0.16), 0px 4px 8px -2px rgba(141, 141, 148, 0.28);
  border-radius: 8px;
  margin-top: 20px;
  padding: 20px;
  height: fit-content;
}

.middle-sect {
  width: 75%;
}

.right-sect {
  width: calc(25% - 40px);
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
  margin-top: 0;
}

.tag {
  display: inline-block;
  padding: 2px 8px;
  background: #F0F0F5;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  color: #0E0D0F;
  margin-bottom: 4px;
  margin-right: 8px;

  $bgColors: ('cyan': #83CCFD, 'green': #A8F0BD, 'blue': #1732be, 'light-blue': #F2F7FE, 'yellow': #FDF6D9, 'red': #FED8D9,
    'magenta': #FFD8EA, 'purple': #F5F1FD, 'orange': #FFF2E8, 'light-green': #DFFEE7, 'light-red': #FFEFEF);

  @each $name,
  $code in $bgColors {
    &.#{$name} {
      background: $code !important;
    }
  }

  $textColors: (
    'white': var(--color-white),
    'magenta': #500224,
    'purple': #4B1F8C,
    'blue': #00309E,
    'orange': #602A00,
    'green': #034216,
    'red': #7A1016
  );

@each $name,
$code in $textColors {
  &.text-#{$name} {
    color: $code !important;
  }
}
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.clickable {
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
}

.sort-icon {
  width: 7px;
  margin-left: 5px;
}

.gray-dot {
    display: inline-block;
    height: 4px;
    width: 4px;
    background: var(--color-gray-1000);
    border-radius: 50%;
    margin: 6px;
    vertical-align: middle;
}

.gray-dot {
    display: inline-block;
    height: 4px;
    width: 4px;
    background: var(--color-gray-1000);
    border-radius: 50%;
    margin: 6px;
    vertical-align: middle;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.bordered-con {
  padding: 9px;
  min-height: 40px;
  border: 1px solid #D8DAE3;
  border-radius: 4px;
  background: #fff;

  &.bg-grey {
    background: #F0F0F5;
    border: 1px solid var(--color-gray-1000);
  }

  &.bg-dark-grey {
    background: #D8DAE3;
    border: 1px solid var(--color-gray-1000);
  }

  &.bg-yellow {
    background: #FFFBEC;
    border: 1px solid #D4A408;
    color: #B95000;
  }

  &.bg-red {
    background: #FED8D9;
    border: 1px solid #D90056;
    color: var(--warning-color-dark);
  }

  &.bg-green {
    background: #E2F8F4;
    border: 1px solid #0AC5A1;
    color: #01876D;
  }

  &.bg-blue {
    background: #E3F3FC;
    border: 1px solid #0270C4;
  }
}

$colors: ('blue': var(--primary-color-light), 'grey': var(--color-gray-1000));
@each $name, $code in $colors {
    .color-#{$name} { color: $code !important; }
}

$colors: ('blue': var(--primary-color-light), 'grey': var(--color-gray-1000));
@each $name, $code in $colors {
    .color-#{$name} { color: $code !important; }
}

//For filters and search above datatable

.datatable-filter {
  &.search {
    width: 350px;
  }

  .mat-mdc-input-element,
  .mat-mdc-select-value-text,
  .mat-mdc-select-placeholder,
  .mat-date-range-input {
      font-size: 12px !important;
  }

  .mat-mdc-input-element,
  .mat-mdc-select-value-text,
  .mat-mdc-select-placeholder,
  .mat-date-range-input,
  .label {
      font-size: 0.75rem !important;
  }

  .mat-mdc-form-field-flex {
      align-items: center;
      padding: 0 !important;
  }

  .mat-mdc-form-field-infix {
      padding: 12px 2px 12px 2px !important;
      border: none;
      align-items: center !important;
  }

  .mat-mdc-form-field {
      padding: 0;
      margin: 0 !important;
  }

  .mat-mdc-select-arrow-wrapper {
      margin-right: 10px;
      margin-top: 10px;
  }

  .mat-mdc-select-value {
      margin-left: 8px;
  }

  .mat-mdc-form-field {
      height: 20px !important;
  }

  .mat-mdc-select-trigger {
      position: relative;
      bottom: -2px;
  }
}

.deleteTheme {
  color: var(--color-red-60);
}

.archived-goals-main-con, .user-group-detail-table-con, .auto-cluster-metric-main-con, .course-container,
.quick-action-card-container .card, .quick-action-card-container .card .card-items, .home-container,
.home-description .personalized-section {
  background: var(--color-white);
}

.pb-12 {
  padding-bottom: 12px;
}

.pt-12 {
  padding-top: 12px;
}

.pl-12 {
  padding-left: 12px;
}

.pr-12 {
  padding-right: 12px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.item-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dynamic-tag {
  padding: 2px 8px;
  height: 14px;
  background-color: #D02670;
  border-radius: 20px;
  margin-left: 8px;
  line-height: 12px;
}

.skeleton-loader {
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  animation-delay: 0.5s;
  background: #F0F0F5 no-repeat !important;
  min-width: 10px;
  min-height: 10px;
}

@keyframes skeleton-loader {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.pulse {
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  animation-delay: 0.5s;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.circle {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  color: white;
  background-color: var(--primary-color-light);
  font-size: 10px;
  margin-right: 4px;
  font-weight: bolder;
  text-transform: uppercase;
  flex: 0 0 12px;
}

@keyframes lineDraw {
  0% {
    width: 0%;
  }

  50% {
    width: 50%;
  }

  100% {
    width: 100%;
  }
}

@keyframes moveArrows {
  0% {
    transform: translateX(0%)
  }

  50% {
    transform: translateX(50%)
  }

  100% {
    transform: translateX(100%)
  }
}

@keyframes draw {
  0% {
    stroke-dashoffset: 200%;
  }

  50% {
    stroke-dashoffset: 50%;
  }

  100% {
    stroke-dashoffset: 0%
  }
}

@keyframes fadeRight {
  0% {
    width: calc(100% + 20px);
  }

  50% {
    width: 50%;
  }

  100% {
    width: 0%;
  }
}


@keyframes increaseHeight {
  0% {
    height: 60px;
  }

  100% {
    height: 100%;
  }
}

@keyframes decreaseHeight {
  0% {
    height: 100%;
  }

  100% {
    height: 60px;
  }
}

@keyframes FadeDown {
  0% {
    transform: translateY(-60px);
  }

  50% {
    opacity: 1;
    transform: translateY(0px);
  }

  100% {
    opacity: 2;
    transform: translateY(0px);
  }
}

@keyframes dot-typing {
  0%, 100% {
    transform: translateY(0);
  }
  33% {
    transform: translateY(-5px);
  }
  66% {
    transform: translateY(0);
  }
}
