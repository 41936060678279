.idp {
  min-height: auto !important;
  // Table overrides
  .mat-mdc-header-cell {
    &.left-align {
      text-align: left;
    }

    &.right-align {
      text-align: right;
    }

    &.center-align {
      text-align: center;
    }
  }

  .mat-mdc-cell {
    &.left-align {
      text-align: left;
    }

    &.right-align {
      text-align: right;
    }

    &.center-align {
      text-align: center;
    }
  }
  
  .mat-mdc-form-field {
    padding-bottom: 0 !important;
  }
  
  .mat-mdc-text-field-wrapper {
    padding-bottom: 0 !important;
  }

  .cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    color: white;
  }

  .mat-mdc-menu-content:not(:empty) {
    padding: 0 !important;
  }

  .mat-accordion .mat-expansion-panel:last-of-type {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .mat-accordion .mat-expansion-panel:first-of-type {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
}
