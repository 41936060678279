.sms-text-input {
  box-sizing: border-box;
  width: 100%;
  padding: 10px 12px;
  color: var(--color-black-text);
  border-radius: 4px;
  border: 1px solid var(--color-gray-75);
  background: var(--color-white);

  &:hover {
    cursor: pointer;
    border: 1px solid var(--primary-color-light);
  }

  &:focus {
    border: 2px solid var(--primary-color-light);
  }

  &-error {
    border: 1px solid var(--warning-color-dark);
  }

  &:disabled{
    background-color: var(--color-gray-0);

    &:hover {
      cursor: auto;
      border: 1px solid var(--color-gray-75);
    }
 }
}
