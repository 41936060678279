.admin-panel {
    min-height: 200px;
    background-color: #fff;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid #eee;
    padding: 28px;
    margin-bottom: 20px;
}

.admin-panel-half-con {
    display: flex;
    justify-content: space-between;
    flex: 1;
}

.admin-panel-half {
    width: calc(50% - 10px);
    background-color: #fff;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid #eee;
    padding: 28px;
    margin-bottom: 20px;
}

.admin-tabs {
    margin-top: 20px;
}

// inputs settings
.admin-form {
    margin-top: 25px;
}

.admin-form-con {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 0px;
}

.admin-text-area-field .mat-mdc-form-field-flex {
    align-items: center;
}

::ng-deep .admin-text-area-field {
    width: 100%;
}

.admin-input-con-half, .admin-input-con-full {
    box-sizing: border-box;
    margin: 0 0 15px 0;
    display: inline-block;
    padding: 0;
}

.admin-input-con-half {
    width: calc(50% - 10px);
}

.admin-input-con-full {
    width: 100%;
}

.admin-input-con-full .input-form-field,
.admin-input-con-half .input-form-field {
    width: 100%;
}

.admin-input-con-full .mat-mdc-form-field,
.admin-input-con-half .mat-mdc-form-field {
    width: 100%;
}

.admin-input-con-half .mat-mdc-select,
.admin-input-con-full .mat-mdc-select,
.admin-input-con-half .mat-mdc-input-element,
.admin-input-con-full .mat-mdc-input-element {
    color: #333333 !important;
}

.admin-input-con-half .mat-icon,
.admin-input-con-full .mat-icon {
    color: var(--color-gray-12);
    font-size: 16px;
    margin-top: 4px;
    height: 16px;
    width: 16px;
}

.admin-save-button-con {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 8px 0 0 0;
    column-gap: 10px;
}

@media screen and (max-device-width: 900px) {
    .admin-panel {
        padding: 15px;
    }
}
