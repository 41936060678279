@use "@angular/material" as mat;

$default-primary-palette: (
    10: #d3f9f9,
    50: #e4e8f9,
    100: #bcc6ef,
    200: #8fa0e5,
    300: #6279db,
    400: var(--primary-color-lightest),
    500: var(--primary-color-light),
    600: #1b3ac6,
    700: var(--primary-color),
    800: #122ab8,
    900: var(--primary-color-dark),
    1000: #2E308E,
    A100: #d8dbff,
    A200: #a5acff,
    A400: #727dff,
    A700: #5866ff,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #ffffff,
    ),
);

$default-accent-palette: (
    50: #e0f4fd,
    100: #b3e3fa,
    200: #80d1f6,
    300: #4dbef2,
    400: #26b0f0,
    500: #00a2ed,
    600: #009aeb,
    700: var(--accent-color),
    800: var(--accent-color-dark),
    900: #0075e0,
    A100: #ffffff,
    A200: #d4e8ff,
    A400: #a1cdff,
    A700: #88bfff,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);
$default-yellow-palette: (
    10: #fdf6d9,
    20: #8f6e00,
    50: #fff7ea,
    100: #ffecca,
    200: #ffdfa7,
    300: #ffd284,
    400: #ffc869,
    500: #ffbe4f,
    600: #ffb848,
    700: #ffaf3f,
    800: #ffa736,
    900: #ff9926,
    1000: #F5BC00,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffead4,
    A700: #ffddba,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #000000,
        900: #000000,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);
$default-warn-palette: (
    10: #ffebf5,
    20: #d02670,
    50: #ffedeb,
    100: #ffd2ce,
    200: #ffb4ad,
    300: #ff968c,
    400: #ff8074,
    500: #ff695b,
    600: #ff6153,
    700: #ff5649,
    800: #ff4c40,
    900: #ff3b2f,
    1000: #DA1E28,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffdcda,
    A700: #ffc4c1,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #000000,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);
$default-success-palette: (
    10: #007d7a,
    50: #e8f9ef,
    100: #c5f0d7,
    200: #9ee6bc,
    300: #77dba1,
    400: #5ad48d,
    500: #3dcc79,
    600: #37c771,
    700: #2fc066,
    800: #27b95c,
    900: #1aad49,
    1000: #177E39,
    A100: #e3ffeb,
    A200: #b0ffc7,
    A400: #7dffa2,
    A700: #63ff90,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #000000,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);
$default-black-palette: (
    50: #e0e0e0,
    100: #b3b3b3,
    200: #808080,
    300: #4d4d4d,
    400: #262626,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    1000: #0E0D0F,
    A100: #a6a6a6,
    A200: #8c8c8c,
    A400: #737373,
    A700: #666666,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #ffffff,
        A700: #ffffff,
    ),
);
$default-gray-palette: (
    50: #f2f2f4,
    100: #dfe0e4,
    200: #cacbd2,
    300: #b4b6c0,
    400: #a4a6b3,
    500: #9496a5,
    600: #8c8e9d,
    700: #818393,
    800: #77798a,
    900: #656879,
    1000: var(--color-gray-1000),
    A100: #f0f2fe,
    A200: #bfc8fc,
    A400: #899aff,
    A700: #6f84ff,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);
$default-white-palette: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #000000,
        900: #000000,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$default-typography: mat.m2-define-typography-config(
    $font-family: 'Inter, sans-serif;',
    $body-1: mat.m2-define-typography-level(inherit, 1.2, 400),
);

$default-primary: mat.m2-define-palette($default-primary-palette);
$default-accent: mat.m2-define-palette($default-accent-palette);
$default-yellow: mat.m2-define-palette($default-yellow-palette);
$default-warn: mat.m2-define-palette($default-warn-palette);
$default-success: mat.m2-define-palette($default-success-palette);
$default-black: mat.m2-define-palette($default-black-palette);
$default-gray: mat.m2-define-palette($default-gray-palette);
$default-white: mat.m2-define-palette($default-white-palette);

$default-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $default-primary,
            accent: $default-accent,
            warn: $default-warn,
            success: $default-success,
            foreground: $default-black,
        ),
    )
);

@include mat.all-component-typographies($default-typography);
@include mat.elevation-classes();
@include mat.app-background();
@include mat.all-component-themes($default-theme);

$codes: 10, 20, 30, 40, 50, 60, 70, 80, 90, 100 !default;
$color-palettes: (
  "green": $default-success,
  "yellow": $default-yellow,
  "warn": $default-warn,
  "primary": $default-primary
) !default;

@each $color-name, $color-value in $color-palettes {
  @each $code in $codes {
    .#{$color-name}-#{$code} {
      color: mat.m2-get-color-from-palette($color-value, $code);
    }
    .bg-#{$color-name}-#{$code} {
      background: mat.m2-get-color-from-palette($color-value, $code);
    }
  }
}
