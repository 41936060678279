@use "utilities-elevation.scss";

//box-sizing
.border-box { box-sizing: border-box !important; }
.content-box { box-sizing: content-box !important; }
//Align
.align-baseline    { vertical-align: baseline !important; }
.align-top         { vertical-align: top !important; }
.align-middle      { vertical-align: middle !important; }
.align-bottom      { vertical-align: bottom !important; }
.align-text-bottom { vertical-align: text-bottom !important; }
.align-text-top    { vertical-align: text-top !important; }
//Border
.border-0        { border: 0 !important; }
.border-top-0    { border-top: 0 !important; }
.border-right-0  { border-right: 0 !important; }
.border-bottom-0 { border-bottom: 0 !important; }
.border-left-0   { border-left: 0 !important; }
.border-none     { border: none !important; }
.rounded-circle  { border-radius: 50% !important; }
.rounded-0       { border-radius: 0 !important; }
//Flex
.flex-row            { flex-direction: row !important; }
.flex-column         { flex-direction: column !important; }
.flex-row-reverse    { flex-direction: row-reverse !important; }
.flex-column-reverse { flex-direction: column-reverse !important; }
.flex-wrap         { flex-wrap: wrap !important; }
.flex-nowrap       { flex-wrap: nowrap !important; }
.flex-wrap-reverse { flex-wrap: wrap-reverse !important; }
.flex-fill         { flex: 1 1 auto !important; }
.flex-1            { flex: 1 !important; }
.flex-half          { flex: 0.5 !important; }
.flex-grow-0       { flex-grow: 0 !important; }
.flex-grow-1       { flex-grow: 1 !important; }
.flex-grow-2       { flex-grow: 2 !important; }
.flex-shrink-0     { flex-shrink: 0 !important; }
.flex-shrink-1     { flex-shrink: 1 !important; }
.justify-content-start   { justify-content: flex-start !important; }
.justify-content-end     { justify-content: flex-end !important; }
.justify-content-center  { justify-content: center !important; }
.justify-content-between { justify-content: space-between !important; }
.justify-content-around  { justify-content: space-around !important; }
.align-items-start    { align-items: flex-start !important; }
.align-items-end      { align-items: flex-end !important; }
.align-items-center   { align-items: center !important; }
.align-items-baseline { align-items: baseline !important; }
.align-items-stretch  { align-items: stretch !important; }
.align-content-start   { align-content: flex-start !important; }
.align-content-end     { align-content: flex-end !important; }
.align-content-center  { align-content: center !important; }
.align-content-between { align-content: space-between !important; }
.align-content-around  { align-content: space-around !important; }
.align-content-stretch { align-content: stretch !important; }
.align-self-auto     { align-self: auto !important; }
.align-self-start    { align-self: flex-start !important; }
.align-self-end      { align-self: flex-end !important; }
.align-self-center   { align-self: center !important; }
.align-self-baseline { align-self: baseline !important; }
.align-self-stretch  { align-self: stretch !important; }
//Float
.float-left  { float: left !important; }
.float-right { float: right !important; }
.float-none  { float: none !important; }
.w-fit-content { width: fit-content !important;}
.w-50 { width: 50% !important;}
.h-50 { height: 50% !important;}
.h-fit-content { height: fit-content !important;}
.w-max-content { width: max-content !important;}
.h-max-content { height: max-content !important;}
.mw-100 { max-width: 100% !important; }
.mh-100 { max-height: 100% !important; }
.mw-70 { max-width: 70% !important; }
.mw-65 { max-width: 65% !important; }
.mw-30 { max-width: 30% !important; }
.min-w-0 { min-width: 0 !important; }
.min-h-0 { min-height: 0 !important; }
.min-vw-100 { min-width: 100vw !important; }
.min-vh-100 { min-height: 100vh !important; }
.vw-100 { width: 100vw !important; }
.vh-100 { height: 100vh !important; }
// Some special margin utils
.m-auto { margin: auto !important; }
.mt-auto,.my-auto { margin-top: auto !important; }
.mr-auto,.mx-auto { margin-right: auto !important; }
.mb-auto,.my-auto { margin-bottom: auto !important; }
.ml-auto,.mx-auto { margin-left: auto !important; }
//Visibility
.visible   { visibility: visible !important; }
.invisible { visibility: hidden !important; }
//Text
.text-justify  { text-align: justify !important; }
.text-wrap     { white-space: normal !important; }
.text-nowrap   { white-space: nowrap !important; }
// Text alignment
.text-left   { text-align: left !important; }
.text-right  { text-align: right !important; }
.text-center { text-align: center !important; }
.text-end { text-align: end !important; }
// Text decoration
.text-underline { text-decoration: underline !important; }
.text-line-through { text-decoration: line-through !important; }
.text-none { text-decoration: none !important; }
// Text case
.text-lowercase  { text-transform: lowercase !important; }
.text-uppercase  { text-transform: uppercase !important; }
.text-capitalize { text-transform: capitalize !important; }
.font-weight-light   { font-weight: 300 !important; }
.font-weight-lighter { font-weight: lighter !important; }
.font-weight-normal  { font-weight: 400 !important; }
.font-weight-bold    { font-weight: 700 !important; }
.font-weight-bolder  { font-weight: bolder !important; }
.font-italic         { font-style: italic !important; }
//Grid
.grid-temp-col-2 { grid-template-columns: 1fr 1fr !important; }
.grid-temp-col-3 { grid-template-columns: 1fr 1fr 1fr !important; }
.grid-temp-col-4 { grid-template-columns: 1fr 1fr 1fr 1fr !important; }
.grid-temp-col-5 { grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important; }
.grid-temp-col-6 { grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr !important; }
.grid-temp-col-211, .gtc-211 { grid-template-columns: 2fr 1fr 1fr !important;}
.object-cover { object-fit: cover !important; }
.border-radius-circle { border-radius: 999px; }

$cursor-pointers: pointer, not-allowed, default, crosshair, copy, grab;
$positions: static, relative, absolute, fixed, sticky !default;
$overflows: auto, hidden, visible, scroll !default;
$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex, grid !default;
$sizes: (10: 10%, 13:13%, 20: 20%, 25: 25%, 30: 30%, 40: 40%, 50: 50%, 60: 60%, 70: 70%, 75: 75%, 80: 80%, 85: 85%, 90: 90%, 100: 100%, auto: auto, fit: fit-content);
$numbers: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 40, 64, 80, 96, 120, 140;
$decimals: (1: 0.1, 2: 0.2, 3: 0.3, 4: 0.4, 5: 0.5, 6: 0.6, 7: 0.7, 8: 0.8, 9: 0.9 );
$percentages: (25: 25%, 30: 30%, 50: 50%, 60: 60%, 75: 75%, 90: 90%, 100: 100%, 110: 110%);
$borders-sizes: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 20, 24;
$weights: 300, 400, 500, 700;
$spacer: 1rem !default;
$spacers:  (0: 0, 1: ($spacer * .25), 2: ($spacer * .5), 3: $spacer, 4: ($spacer * 1.5), 5: ($spacer * 3),
    6: ($spacer * 5), 1px: 1px, 2px: 2px, 10: 10px, 12: 12px, 15: 15px, 20: 20px, 32: 32px, 40: 40px, 48: 48px);

@each $size in $borders-sizes {
    .border-#{$size} { border: ($size * 1px) solid; }
    .border-top-#{$size} { border-top: ($size * 1px) solid; }
    .border-right-#{$size} { border-right: ($size * 1px) solid; }
    .border-bottom-#{$size} { border-bottom: ($size * 1px) solid; }
    .border-left-#{$size} { border-left: ($size * 1px) solid; }
    .border-radius-#{$size} { border-radius: ($size * 1px) !important; }
    .border-radius-tl-#{$size} { border-top-left-radius: ($size * 1px) !important; }
    .border-radius-tr-#{$size} { border-top-right-radius: ($size * 1px) !important; }
    .border-radius-bl-#{$size} { border-bottom-left-radius: ($size * 1px) !important; }
    .border-radius-br-#{$size} { border-bottom-right-radius: ($size * 1px) !important; }
}
@each $key, $value in $decimals {
    .opacity-#{$key} { opacity: ($value * 1) !important; }
    .opacity-hover-#{$key}:hover { opacity: ($value * 1) !important; }
}
@each $size, $length in $percentages {
    .flex-basis-#{$size} { flex-basis: $length !important; }
}
//Display
@each $value in $displays {
    .d-#{$value} { display: $value !important; }
}
//cursor-type
@each $cursor-pointer in $cursor-pointers {
    .cursor-#{$cursor-pointer} { cursor: $cursor-pointer !important; }
}
//Overflow
@each $value in $overflows {
    .overflow-#{$value} { overflow: $value !important; }
    .overflow-x-#{$value} { overflow-x: $value !important; }
    .overflow-y-#{$value} { overflow-y: $value !important; }
}
//Position
@each $position in $positions {
    .position-#{$position} { position: $position !important; }
}
//Sizing
@each $prop, $abbrev in (width: w, height: h, min-width: min-w, min-height: min-h) {
    @each $size, $length in $sizes {
        .#{$abbrev}-#{$size} { #{$prop}: $length !important; }
    }
}
@each $size, $length in $spacers {
    // Margin and Padding
    @each $prop, $abbrev in (margin: m, padding: p) {
        .#{$abbrev}-#{$size} { #{$prop}: $length !important; }
        .#{$abbrev}t-#{$size},
        .#{$abbrev}y-#{$size} {
            #{$prop}-top: $length !important;
        }
        .#{$abbrev}r-#{$size},
        .#{$abbrev}x-#{$size} {
            #{$prop}-right: $length !important;
        }
        .#{$abbrev}b-#{$size},
        .#{$abbrev}y-#{$size} {
            #{$prop}-bottom: $length !important;
        }
        .#{$abbrev}l-#{$size},
        .#{$abbrev}x-#{$size} {
            #{$prop}-left: $length !important;
        }
    }
    // Negative margins (e.g., where `.mb-n1` is negative version of `.mb-1`)
    @if $size != 0 {
        .m-n#{$size} { margin: -$length !important; }
        .mt-n#{$size},
        .my-n#{$size} {
            margin-top: -$length !important;
        }
        .mr-n#{$size},
        .mx-n#{$size} {
            margin-right: -$length !important;
        }
        .mb-n#{$size},
        .my-n#{$size} {
            margin-bottom: -$length !important;
        }
        .ml-n#{$size},
        .mx-n#{$size} {
            margin-left: -$length !important;
        }
    }
    // top, right, bottom and left
    .t-#{$size} {
        top: $length !important;
    }
    .t-n#{$size} {
        top: -$length !important;
    }
    .r-#{$size} {
        right: $length !important;
    }
    .b-#{$size} {
        bottom: $length !important;
    }
    .l-#{$size} {
        left: $length !important;
    }
    // Gap
    .gap-#{$size} { gap: $length !important; }
    .row-gap-#{$size} { row-gap: $length !important; }
    .col-gap-#{$size} { column-gap: $length !important; }
    // line-height
    .lh-#{$size} { line-height: $length !important; }
}
// letter-spacing
@each $size, $space in $decimals {
    .ls-#{$size} { letter-spacing: $space + px !important; }
}
// Weight and italics
@each $weight in $weights {
    .fw-#{$weight} { font-weight: $weight !important; }
}
@each $size in $numbers {
    .order-#{$size} { order: $size !important; }

    .flex-#{$size} { flex: $size !important; }

    .flex-grow-#{$size} { flex-grow: $size !important; }
    // height
    .h-#{$size}px, .ic-#{$size} { height: $size + px !important; }
    .min-h-#{$size}px { min-height: $size + px !important; }
    .max-h-#{$size}px { max-height: $size + px !important; }
    // width
    .w-#{$size}px, .ic-#{$size} { width: $size + px !important; }
    .min-w-#{$size}px { min-width: $size + px !important; }
    .max-w-#{$size}px { max-width: $size + px !important; }
    // font size
    .fs-#{$size}, .ic-#{$size} { font-size: $size + px !important; }
    // Grid
    .grid-cols-#{$size} { grid-template-columns: repeat($size, 1fr) !important; }
    .col-span-#{$size} { grid-column: span $size !important; }
    .row-span-#{$size} { grid-row: span $size !important; }
    // top, right, bottom and left in pixels
    .t-#{$size}px {
        top: $size + px !important;
    }
    .t-n#{$size}px {
        top: -$size + px !important;
    }
    .r-#{$size}px {
        right: $size + px !important;
    }
    .b-#{$size}px {
        bottom: $size + px !important;
    }
    .l-#{$size}px {
        left: $size + px !important;
    }
}
.break-word { word-break: break-word !important;}
